<template>
    <div class="blogs-page">
        <section id="" class="blue-bar">
            <h1 class="title-blog">Crafting Tomorrow's Teams: A Deep Dive into Recruitment, Skills, and Tech Integration.</h1>
        </section>
        <section id="" class="search-container">
            <div class="left-side">
                <h1>Insights from our team</h1>
                <p>Powerful Trading Tools and Features for Experienced Investors</p>
            </div>
            <div class="right-side">
                <input class="pl-20" type="text" placeholder="search for articles ..." v-model="searchQuery" />
            </div>
        </section>
        <section class="blogs-container">
            <div class="side-bar">
                <div class="topics-list">
                    <span class="title">Categories</span>

                    <span class="categories-span" :class="{ 'text-underline': $route.query.category === 'All' }" @click="getAllBlogs()">All</span>
                    <span
                        class="categories-span"
                        v-for="category in categoriesList"
                        :class="{ 'text-underline': $route.query.category === category.category }"
                        @click="
                            getByCategory(category._id);
                            getCategoryName(category.category);
                        "
                        :key="category._id"
                        >{{ category.category }}</span
                    >

                    <!--   <div class="flex flex-col w-3/4">
                        <span class="h-1 rounded-full bg-gray-300 w-0.5/2 my-auto"></span>
                        <span class="h-1 rounded-full bg-gray-300 w-11/12 my-auto"></span>
                        <span class="h-1 rounded-full bg-gray-300 w-full my-auto"></span>
                        <span class="h-1 rounded-full bg-gray-300 w-3/4 my-auto"></span>
                    </div>

                --></div>
                <div class="w-full">
                    <div class="w-full h-75 flex flex-col items-center justify-center gap-4 bg-white shadow-[0_0_8px_1px_rgba(0,0,0,30%)] p-6 rounded-md sticky">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/slack.svg" alt="slack" class="" />
                        <h1 class="text-[#2196f3] text-xl font-bold">{{ $t("The GO PLATFORM community") }}</h1>
                        <button
                            class="w-fit py-3 px-8 font-bold text-xl text-white bg-[#2196f3] rounded-3xl hover:scale-105 transition-all duration-200 ease-in hover:shadow-[0_0_4px_2px_rgba(33,150,243,50%)] hover:text-[#2196f3] hover:bg-white"
                        >
                            {{ $t("Join us") }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="right-container">
                <div class="trending-section">
                    <span class="title">Trending Topics</span>
                    <div class="topics-container">
                        <span
                            class="topics"
                            v-for="category in trendingCategoriesList"
                            :class="{ 'trending-actif': $route.query.category === category.category }"
                            @click="
                                getByCategory(category._id);
                                getCategoryName(category.category);
                            "
                            :key="category._id"
                            >{{ category.category }}</span
                        >
                    </div>
                </div>

                <div class="blogs-wrapper">
                    <div v-if="articles.length === 0 || loadingArticles || loading" class="w-full flex flex-col flex-wrap justify-between items-start gap-6">
                        <div class="border border-gray-300 shadow rounded-md gap-3 flex flex-row p-4 h-48 w-full mx-auto animate-pulse" v-for="i in range" :key="i">
                            <!-- <img loading="lazy"  decoding="async" src="" alt=""> -->
                            <div class="flex flex-col w-3/4">
                                <span class="h-2 rounded-full bg-gray-300 w-full my-auto"></span>
                                <span class="h-2 rounded-full bg-gray-300 w-4/5 my-auto"></span>
                                <span class="h-1 rounded-full bg-gray-300 w-11/12 my-auto"></span>
                                <span class="h-1 rounded-full bg-gray-300 w-full my-auto"></span>
                                <span class="h-1 rounded-full bg-gray-300 w-11/12 my-auto"></span>
                                <span class="h-1 rounded-full bg-gray-300 w-full my-auto"></span>
                            </div>
                            <span class="w-40 h-40 rounded-md bg-gray-300"></span>
                        </div>
                    </div>
                    <div v-else class="blog-cards">
                        <ArticleCardList
                            v-for="article in filteredArticles"
                            :key="article._id"
                            :id="article._id"
                            :author="article.author"
                            :image="article.image"
                            :description="article.description"
                            :title="article.title"
                            :category="article.category.category"
                            :time="article.reading_time"
                            :text="article.text"
                            :date="getDate(article.date)"
                        />
                    </div>
                </div>
            </div>
        </section>
        <section class="bottom_text">
            <img loading="lazy" decoding="async" src="../assets/landing-page-logos/bottom_text.svg" />
            <span>{{ $t("COMMUNITY") }}</span>
            <h1 v-html="$t('help-text')"></h1>
            <p>
                {{ $t("community-text") }}
            </p>
            <div class="slack justify-center lg:justify-between">
                <img loading="lazy" decoding="async" class="logo-slack" src="../assets/Images/icons/slack.svg" />
                <span
                    >{{ $t("Join Slack") }}
                    <img loading="lazy" decoding="async" class="arrow" src="../assets/Images/arrow_to_right.svg" />
                </span>
            </div>
        </section>
    </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
//import { Carousel, Slide } from "vue3-carousel";
//import articleCard from "@/components/articleCard.vue";
import ArticleCardList from "@/components/articleCardList.vue";
import axios from "axios";
// import cardsLoader from "@/components/cardsLoader.vue"
import { useStore } from "../store/index";
//import LoaderComponent from "@/components/LoaderComponent.vue";
// import axios from "axios";
export default {
    name: "BlogsPage",
    components: {
        // articleCard,
        // Carousel,
        //Slide,
        ArticleCardList,
        // LoaderComponent
    },
    data() {
        return {
            grid: false,
            range: [1, 2, 3, 4, 5],
            loading: false,
            title: "",
            categoriesList: [],
            articlesList: [],
            searchQuery: "",
            category: "",
            trendingCategoriesList: [],
            loadingCategories: true,

            // articles: [],
        };
    },
    computed: {
        articles() {
            return this.Store.getArticles;
        },
        loadingArticles() {
            return this.Store.getLoadingArticles;
        },

        filteredArticles() {
            return this.Store.articles.filter((article) => article.title.toLowerCase().includes(this.searchQuery.toLowerCase()));
        },
    },
    methods: {
        getImgUrl(imgFileId) {
            if (imgFileId) {
                var image = `data:image/png;base64,${imgFileId}`;
                return image;
            }
            return require("../assets/Images/user.png");
        },
        getDate(str) {
            const date = new Date(str);

            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
            };

            const formattedDate = date.toLocaleDateString("en-US", options);
            return formattedDate;
        },
        getCategoryName(categoryName) {
            this.$router.push({ query: { category: categoryName } });
        },
        getByCategory(id) {
            this.loading = true;
            this.category = id;
            axios
                .post(
                    "https://server.go-platform.com/articles/data-category",
                    { category: this.category },
                    {
                        withCredentials: true,
                    },
                )
                .then((response) => (this.Store.articles = response.data))
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getAllBlogs() {
            this.$router.push({ query: { category: "All" } });
            try {
                await this.Store.fetchArticles();
            } catch (error) {
                console.error("Error fetching articles:", error);
            }
        },
        getCategories() {
            axios
                .get("https://server.go-platform.com/categories/", {
                    withCredentials: true,
                })
                .then((response) => (this.categoriesList = response.data.categories))
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => (this.loadingCategories = false));
        },
        getTrendingCategories() {
            axios
                .get("https://server.go-platform.com/categories/", {
                    withCredentials: true,
                })
                .then((response) => (this.trendingCategoriesList = response.data.categories.slice(0, 3)))
                .catch((err) => {
                    console.log(err);
                });
        },
        sendContactUs() {
            this.loading = true;
            let contactForm = {
                email: this.email,
                name: this.name,
                title: this.title,
                message: this.message,
            };
            axios
                .post("https://server.go-platform.com/contact-us", contactForm, {
                    withCredentials: true,
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                    this.email = "";
                    this.name = "";
                    this.title = "Report a bug";
                    this.message = "";
                });
        },
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    mounted() {
        this.Store.fetchArticles();
        this.$router.push({ query: { category: "All" } });
        this.getCategories();
        this.getTrendingCategories();
        this.Store.observeIntersection();
    },
};
</script>

<style scoped>
.blogs-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
    height: 100%;
    background: #fff;
}

.blue-bar {
    border-radius: 0 0 25px 25px;
    background: linear-gradient(180deg, #3361ff 13.03%, #3789ff 70.84%, #00aef0 100%);
    height: 550px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: start;
    width: 100%;
    padding-left: 6rem;
    padding-top: 6rem;
    padding-right: 1rem;
    /* padding-right: 4rem;*/
}

.title-blog {
    color: #fff;
    font-family: Roboto;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
    margin-left: 50px;
    line-height: normal;
}

.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    height: 200px;
    padding: 2.5rem 2rem;
    width: 95%;
    border-bottom: 1px solid #e3e3e3;

    p {
        color: #7d7d7d;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
    }
}

.search-container h1 {
    color: #1e1e1e;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
}

.left-side {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 0px;
    width: 50%;
    height: 100%;
}

.right-side {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    width: 50%;
    height: 100%;
}

input {
    width: 70%;
    border-radius: 60px;
    border: 1px solid #dadada;
    background: #fff;
    padding: 0.5rem;
}

.blogs-container {
    display: flex;
    justify-content: center;
    width: 70%;
    gap: 50px;
}

.side-bar {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 30px;
    margin-top: 9.5%;
}

.topics-list {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 7px;
    margin-bottom: 3rem;

    padding-left: 2rem;
}

.categories-span {
    color: #1e1e1e;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.text-underline {
    text-decoration: underline;
}

.title {
    color: #00aef0 !important;
    font-family: Roboto;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 20px;
}

.right-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: start;
    justify-content: start;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 3rem;
}

.trending-section {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    border-bottom: 1px solid #e3e3e3;
    padding: 0 0 2rem 0;
    width: 100%;
}

.topics-container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
}

.topics {
    border-radius: 30px;
    border: 1px solid #c9c9c9;
    background: #fff;
    color: #797979;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding: 3px 10px;
    cursor: pointer;
}

.topics:hover {
    background: #eeeff0;
}

.trending-actif {
    background: #eeeff0;
}

.blogs-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
}

.blog-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
    gap: 50px;
}

.slack {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    gap: 1rem;
    margin: 5% auto;
}

.logo-slack {
    width: 56.25px !important;
    height: 56.25px;
}

.slack span {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.slack span img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

.bottom_text {
    width: 80%;
    margin: 10% 10%;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    border: 2px solid #ccd7ec;
    background: #fff;
    font-family: Roboto;
    overflow: hidden;
    padding-top: 0.25rem;
}

.bottom_text h1 {
    color: var(--blue-gray-900, #0f172a);

    text-align: center;
    font-family: Roboto;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    line-height: 115%;
    margin: 2%;
}

.bottom_text img {
    width: 100%;
    margin-top: -1%;
}

.bottom_text p {
    width: 80%;
    margin: 0 10%;
    color: var(--blue-gray-900, #0f172a);
    text-align: center;
    /* Body/XL */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    margin-bottom: 3%;
}

.bottom_text span {
    color: #3789ff;
    margin-top: 3%;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 300%;
    /* 60px */
    letter-spacing: 3px;
    text-transform: uppercase;
}
</style>
