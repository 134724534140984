<template>
    <div class="py-32 px-16 flex flex-col justify-start gap-10 w-full">
        <section class="w-full shadow-[0_0_8px_1px_rgba(0,0,0,30%)] rounded h-[280px] md:h-[500px]">
            <carousel :autoplay="8000" :pause-autoplay-on-hover="true" :wrap-around="true" :transition="700">
                <slide v-for="blog in articles.slice(0, 3)" :key="blog._id" class="w-full h-full relative group">
                    <!-- <div class="flex flex-col relative">
                        <div class="w-full pl-8 flex flex-col justify-center items-start gap-5 absolute left-0 bottom-0">
                            <h1 class="text-gray-700 font-bold text-5xl text-left leading-[4rem] w-full">{{ blog.title }}</h1>
                            
                            <h3 class="text-gray-500 font-bold text-xl text-left w-full">{{ blog.author }}, {{ getDate(blog.date) }}</h3>
                           
                            <a
                                href="#"
                                class="w-fit py-3 px-8 font-bold text-xl text-white bg-[#2196f3] rounded-3xl hover:scale-105 transition-all duration-200 ease-in hover:shadow-[0_0_4px_2px_rgba(33,150,243,50%)] hover:text-[#2196f3] hover:bg-white"
                                >{{ $t("Read more!") }}</a
                            >
                        </div> -->
                    <div class="absolute top-0 right-0 bottom-0 left-0 bg-[rgba(255,255,255,40%)] group-hover:flex justify-center items-center hidden z-20">
                        <router-link
                            :to="`/articles/${blog._id}`"
                            class="w-fit py-5 px-10 font-bold text-2xl text-white bg-[#2196f3] rounded-full hover:scale-105 transition-all duration-200 ease-in hover:shadow-[0_0_4px_2px_rgba(33,150,243,50%)] hover:text-[#2196f3] hover:bg-white"
                            >{{ $t("Read more!") }}</router-link
                        >
                    </div>
                    <div class="img_clip w-full h-full">
                        <img loading="lazy" decoding="async" :src="getImgUrl(blog.image)" :alt="blog.title" class="w-full h-full group-hover:blur-sm" />
                    </div>
                    <!-- </div> -->
                </slide>
            </carousel>
        </section>
        <section class="flex flex-col gap-8">
            <div
                class="w-full h-fit flex flex-row items-center justify-between py-3 after:content[''] after:w-full after:h-[1.5px] after:bg-gray-300 after:absolute relative after:bottom-0 after:left-0 text-gray-700"
            >
                <span class="area_title text-3xl font-semibold">{{ $t("Discover More Insightful Articles") }}</span>
                <div class="text-3xl font-normal flex flex-row justify-end items-center gap-4">
                    <button class="hover:text-white hover:bg-gray-300 w-10 h-10 rounded group" @click="grid = !grid">
                        <font-awesome-icon :icon="['fas', 'table-list']" :class="grid ? 'text-gray-300 group-hover:text-white' : 'text-[#2196f3]'" />
                    </button>
                    <button class="hover:text-white hover:bg-gray-300 w-10 h-10 rounded group" @click="grid = !grid">
                        <font-awesome-icon :icon="['fas', 'border-all']" :class="!grid ? 'text-gray-300 group-hover:text-white' : 'text-[#2196f3]'" />
                    </button>
                </div>
            </div>

            <div class="w-full flex flex-row gap-5">
                <!-- <cardsLoader/> -->
                <div v-if="articles.length === 0" class="w-4/5 flex flex-col flex-wrap justify-between items-start gap-6">
                    <div class="border border-gray-300 shadow rounded-md gap-3 flex flex-row p-4 h-48 w-full mx-auto animate-pulse" v-for="i in range" :key="i">
                        <!-- <img loading="lazy"  decoding="async" src="" alt=""> -->
                        <span class="w-40 h-40 rounded-md bg-gray-300"></span>
                        <div class="flex flex-col w-1/2">
                            <span class="h-2 rounded-full bg-gray-300 w-full my-auto"></span>
                            <span class="h-2 rounded-full bg-gray-300 w-4/5 my-auto"></span>
                            <span class="h-1 rounded-full bg-gray-300 w-11/12 my-auto"></span>
                            <span class="h-1 rounded-full bg-gray-300 w-full my-auto"></span>
                            <span class="h-1 rounded-full bg-gray-300 w-11/12 my-auto"></span>
                            <span class="h-1 rounded-full bg-gray-300 w-full my-auto"></span>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row flex-wrap items-start gap-6 w-4/5" v-else-if="grid">
                    <!-- <articleCard
                        v-for="article in articles"
                        :key="article._id"
                        :id="article._id"
                        :author="article.author"
                        :image="article.image"
                        :title="article.title"
                        :text="article.text"
                        :date="getDate(article.date)"
                    /> -->
                </div>
                <div class="flex flex-col flex-wrap justify-between items-start gap-6 w-4/5" v-else-if="!grid">
                    <ArticleCardList
                        v-for="article in articles"
                        :key="article._id"
                        :id="article._id"
                        :author="article.author"
                        :image="article.image"
                        :title="article.title"
                        :text="article.text"
                        :date="getDate(article.date)"
                    />
                </div>
                <div class="w-1/4">
                    <div class="w-full h-80 flex flex-col items-center justify-center gap-4 bg-white shadow-[0_0_8px_1px_rgba(0,0,0,30%)] p-6 rounded-md sticky">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/slack.svg" alt="slack" class="" />
                        <h1 class="text-[#2196f3] text-xl font-bold">{{ $t("The GO PLATFORM community") }}</h1>
                        <button
                            class="w-fit py-3 px-8 font-bold text-xl text-white bg-[#2196f3] rounded-3xl hover:scale-105 transition-all duration-200 ease-in hover:shadow-[0_0_4px_2px_rgba(33,150,243,50%)] hover:text-[#2196f3] hover:bg-white"
                        >
                            {{ $t("Join us") }}
                        </button>
                    </div>
                </div>
            </div>
            <!-- <cardsLoader v-else/> -->
        </section>
        <div id="contact-us" ref="contact" class="contact-us">
            <div class="left-side" id="contact">
                <span>{{ $t("Contact us") }}</span>
                <h1>
                    {{ $t("Any questions?") }}<br />
                    {{ $t("Write or call us. We will write back within 12h") }}
                </h1>
                <p>{{ $t("contactText") }}</p>
                <div class="contact-container">
                    <div class="number-container">
                        <font-awesome-icon class="contact-icon" icon="fa-solid fa-phone" />
                        +1 (413) 772 9811
                    </div>
                    <div class="email-container">
                        <font-awesome-icon class="contact-icon" icon="fa-regular fa-envelope" />
                        contact@go-platform.dz
                    </div>
                </div>
            </div>
            <div class="right-side">
                <input v-model="name" type="text" :placeholder="$t('Your Name')" />
                <input v-model="email" type="email" :placeholder="$t('Your Email')" />
                <div class="select-container">
                    <select v-model="title" name="dropdown">
                        <option value="" selected disabled>{{ $t("Select a title") }}</option>
                        <option value="partnership">{{ $t("Partnership") }}</option>
                        <option value="request">{{ $t("Request") }}</option>
                        <option value="urgency">{{ $t("Urgency") }}</option>
                        <option value="feedback">{{ $t("Feedback") }}</option>
                        <option value="bug">{{ $t("Report a bug") }}</option>
                        <option value="other">{{ $t("Others") }}</option>
                    </select>
                </div>
                <textarea v-model="message" style="resize: none" rows="5" id="messageTextarea" name="message" placeholder="Message"></textarea>
                <button class="btn-contact" @click="sendContactUs" v-if="!loading" type="submit">
                    {{ $t("Send") }}
                    <font-awesome-icon class="arrow-right" icon="fa-solid fa-arrow-right" />
                </button>
                <button class="btn-contact btn-loading" v-else>
                    <LoaderComponent />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
// import articleCard from "@/components/articleCard.vue";
import ArticleCardList from "@/components/articleCardList.vue";
import axios from "axios";
// import cardsLoader from "@/components/cardsLoader.vue"
import { useStore } from "../store/index";
import LoaderComponent from "@/components/LoaderComponent.vue";
// import axios from "axios";
export default {
    name: "articlesLibrary",
    components: { /* articleCard, */ Carousel, Slide, ArticleCardList, LoaderComponent },
    data() {
        return {
            grid: false,
            range: [1, 2, 3, 4, 5],
            loading: false,
            title: "",
            // articles: [],
        };
    },
    computed: {
        articles() {
            return this.Store.getArticles;
        },
    },
    methods: {
        getImgUrl(imgFileId) {
            if (imgFileId) {
                var image = `data:image/png;base64,${imgFileId}`;
                return image;
            }
            return require("../assets/Images/user.png");
        },
        getDate(str) {
            const date = new Date(str);
            const extractedDate = date.toISOString().split("T")[0];
            return extractedDate;
        },
        sendContactUs() {
            this.loading = true;
            let contactForm = {
                email: this.email,
                name: this.name,
                title: this.title,
                message: this.message,
            };
            axios
                .post("https://server.go-platform.com/contact-us", contactForm, {
                    withCredentials: true,
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                    this.email = "";
                    this.name = "";
                    this.title = "Report a bug";
                    this.message = "";
                });
        },
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    mounted() {
        this.Store.fetchArticles();
    },
};
</script>

<style lang="scss" scoped>
.articles_container {
    display: flex;

    // padding: 5%;
    > :first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        .articles_grid {
            display: flex;
            flex-flow: row wrap;
            gap: 2rem;
        }

        // @media screen and (max-width: 600px) {
        //   .articles_grid {
        //     flex-flow: column;
        //   }
        // }
    }

    .area_title {
        font-weight: 700;
        font-size: 34px;
        line-height: 25px;
        color: #5a5a5d;
        width: 100%;
        text-align: left;
    }
}

.img_clip {
    // clip-path: polygon(45% 0, 100% 0, 100% 100%, 0% 100%);
}

.contact-us {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // background: #1642f8;
    padding: 7rem;
    font-family: Roboto;
    gap: 15%;
    margin-bottom: -5%;
    background: #f4faff;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 1rem 0;

        .left-side {
            width: 100%;
            padding: 1rem 2rem;

            h1 {
                font-size: 40px;
            }

            .contact-container {
                flex-direction: column-reverse;
            }
        }

        .right-side {
            width: 100%;
            padding: 1rem 2rem;
        }

        .right-side {
            input,
            textarea,
            select,
            .select-container {
                width: 100%;
            }
        }
    }

    input {
        width: 55%;
        padding: 1rem;
        border-radius: 10px;
        //  outline-color: #2196f3;
        background-color: #fff;
        outline: none;
        box-shadow:
            0 2px 4px rgba(0, 0, 0, 0.1),
            0 4px 8px rgba(0, 0, 0, 0.1);
    }

    textarea {
        width: 55%;
        padding: 1rem;
        border-radius: 10px;
        background-color: #fff;
        // outline-color: #2196f3;
        box-shadow:
            0 2px 4px rgba(0, 0, 0, 0.1),
            0 4px 8px rgba(0, 0, 0, 0.1);

        outline: none;
    }

    select {
        width: 100%;

        cursor: pointer;

        outline: none;
    }

    /*input:focus,
    textarea:focus,
    select:focus {

        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2);
    }*/

    .arrow-right {
        width: 13px;
        margin-left: 5px;
    }
}

.left-side {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 25px;
    color: #2196f3;
}

.contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 550;
    color: #2196f3;
}

.contact-icon {
    margin-right: 5px;
}

.right-side {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.select-container {
    width: 55%;
    padding: 1rem;
    border-radius: 10px;
    background-color: #fff;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: start;
}

.btn-contact {
    width: 55%;
    height: 60px;
    padding: 1rem;
    border-radius: 10px;
    border: none;
    background: #2196f3;
    font-weight: 500;
    color: #fff;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-loading {
    opacity: 0.85;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-us p {
    text-align: start;
    font-family: Roboto;
    font-style: normal;
    color: #263238;
}

.contact-us h1 {
    text-align: start;
    font-family: Roboto;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    color: #263238 !important;
}

.contact-us span {
    text-align: start;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    color: #2196f3;
}
</style>
<!-- 
                {
                    _id: { $oid: "64135930a7ff52e1abc34cd7" },
                    image: "our_company2.jpg",
                    title: "How to make the best of your 24 hours? Best tips for increasing your productivity",
                    text: "Do you often find your day spiralling out of control, and finish it feeling like you weren’t able to accomplish everything on your to-do list? You start each day with a plan, a list, a goal… but by the end of\\the day, you find that list even getting longer? And you find yourself becoming distracted, not prioritizing, \\and procrastinating! We get it. Being productive can be difficult, but it’s not rocket science either… So, how \\can you regain control of your time? First, let’s clarify that we all have 24 hours per day; the key to \\productivity is how you make the most of those 24 hours, how you get much done in those 24 hours, and how you manage \\to finish a 5 hours-long task in 20 minutes. As the saying goes “it’s not the number of hours you put into work; it \\is the amount of work you put in those hours.” But how you do that? Simply, by working “smarter” not “harder”; and by \\being “super productive” instead of being “super busy”; This resource will provide you with 12 simple but effective \\strategies for increasing your productivity. As always, let’s start with the basics: What is productivity?",
                    date: "23/01/2023",
                    author: "Jhon Smith",
                },
                {
                    _id: { $oid: "64135930a7ff52e1abc34cd7" },
                    image: "our_company2.jpg",
                    title: "How to make the best of your 24 hours? Best tips for increasing your productivity",
                    text: "Do you often find your day spiralling out of control, and finish it feeling like you weren’t able to accomplish everything on your to-do list? You start each day with a plan, a list, a goal… but by the end of\\the day, you find that list even getting longer? And you find yourself becoming distracted, not prioritizing, \\and procrastinating! We get it. Being productive can be difficult, but it’s not rocket science either… So, how \\can you regain control of your time? First, let’s clarify that we all have 24 hours per day; the key to \\productivity is how you make the most of those 24 hours, how you get much done in those 24 hours, and how you manage \\to finish a 5 hours-long task in 20 minutes. As the saying goes “it’s not the number of hours you put into work; it \\is the amount of work you put in those hours.” But how you do that? Simply, by working “smarter” not “harder”; and by \\being “super productive” instead of being “super busy”; This resource will provide you with 12 simple but effective \\strategies for increasing your productivity. As always, let’s start with the basics: What is productivity?",
                    date: "23/01/2023",
                    author: "Jhon Smith",
                },
                {
                    _id: { $oid: "64135930a7ff52e1abc34cd7" },
                    image: "our_company2.jpg",
                    title: "How to make the best of your 24 hours? Best tips for increasing your productivity",
                    text: "Do you often find your day spiralling out of control, and finish it feeling like you weren’t able to accomplish everything on your to-do list? You start each day with a plan, a list, a goal… but by the end of\\the day, you find that list even getting longer? And you find yourself becoming distracted, not prioritizing, \\and procrastinating! We get it. Being productive can be difficult, but it’s not rocket science either… So, how \\can you regain control of your time? First, let’s clarify that we all have 24 hours per day; the key to \\productivity is how you make the most of those 24 hours, how you get much done in those 24 hours, and how you manage \\to finish a 5 hours-long task in 20 minutes. As the saying goes “it’s not the number of hours you put into work; it \\is the amount of work you put in those hours.” But how you do that? Simply, by working “smarter” not “harder”; and by \\being “super productive” instead of being “super busy”; This resource will provide you with 12 simple but effective \\strategies for increasing your productivity. As always, let’s start with the basics: What is productivity?",
                    date: "23/01/2023",
                    author: "Jhon Smith",
                },
                {
                    _id: { $oid: "64135930a7ff52e1abc34cd7" },
                    image: "our_company2.jpg",
                    title: "How to make the best of your 24 hours? Best tips for increasing your productivity",
                    text: "Do you often find your day spiralling out of control, and finish it feeling like you weren’t able to accomplish everything on your to-do list? You start each day with a plan, a list, a goal… but by the end of\\the day, you find that list even getting longer? And you find yourself becoming distracted, not prioritizing, \\and procrastinating! We get it. Being productive can be difficult, but it’s not rocket science either… So, how \\can you regain control of your time? First, let’s clarify that we all have 24 hours per day; the key to \\productivity is how you make the most of those 24 hours, how you get much done in those 24 hours, and how you manage \\to finish a 5 hours-long task in 20 minutes. As the saying goes “it’s not the number of hours you put into work; it \\is the amount of work you put in those hours.” But how you do that? Simply, by working “smarter” not “harder”; and by \\being “super productive” instead of being “super busy”; This resource will provide you with 12 simple but effective \\strategies for increasing your productivity. As always, let’s start with the basics: What is productivity?",
                    date: "23/01/2023",
                    author: "Jhon Smith",
                },
                {
                    _id: { $oid: "64135930a7ff52e1abc34cd7" },
                    image: "our_company2.jpg",
                    title: "How to make the best of your 24 hours? Best tips for increasing your productivity",
                    text: "Do you often find your day spiralling out of control, and finish it feeling like you weren’t able to accomplish everything on your to-do list? You start each day with a plan, a list, a goal… but by the end of\\the day, you find that list even getting longer? And you find yourself becoming distracted, not prioritizing, \\and procrastinating! We get it. Being productive can be difficult, but it’s not rocket science either… So, how \\can you regain control of your time? First, let’s clarify that we all have 24 hours per day; the key to \\productivity is how you make the most of those 24 hours, how you get much done in those 24 hours, and how you manage \\to finish a 5 hours-long task in 20 minutes. As the saying goes “it’s not the number of hours you put into work; it \\is the amount of work you put in those hours.” But how you do that? Simply, by working “smarter” not “harder”; and by \\being “super productive” instead of being “super busy”; This resource will provide you with 12 simple but effective \\strategies for increasing your productivity. As always, let’s start with the basics: What is productivity?",
                    date: "23/01/2023",
                    author: "Jhon Smith",
                },
             -->
