<template>
    <div class="archiveWrapper">
        <AllArchiveAssessments :showAssessments="showAssessments" :archivesAssessmentsData="archivesAssessmentsData" :toggleAllAssessmentsArchive="toggleAllAssessmentsArchive" />
        <div class="btns_wrapper">
            <div class="btns">
                <button :class="this.display === 'candidates' ? 'active' : 'notActive'" @click="toggleCandidatesArchive">
                    {{ $t("Candidates") }}
                </button>
                <button :class="this.display === 'assessment' ? 'active' : 'notActive'" @click="toggleAssessmentsArchive">
                    {{ $t("Projects") }}
                </button>
            </div>
        </div>
        <LoadingComponent v-if="isLoading" style="margin-top: 15%" />
        <div v-else>
            <div v-if="this.Store.archivedCandidates.length === 0 && this.display === 'candidates'" class="noCandidates">
                <h1>{{ $t("No archived candidates") }}</h1>
            </div>
            <div class="candidatesWrapper" v-if="this.display === 'candidates'">
                <CandidateArchiveCard
                    v-for="(candidate, i) in this.Store.archivedCandidates"
                    :key="i"
                    :passed="true"
                    :candidate="candidate"
                    :candidateInfo="getCandidate(candidate.candidate)"
                    :toggleLoading="toggleLoading"
                />
            </div>
            <div v-else>
                <ArchivedAssessments :archivesAssessmentsData="archivesAssessmentsData" :toggleAllAssessmentsArchive="toggleAllAssessmentsArchive" />
            </div>
        </div>
    </div>
</template>

<script>
import CandidateArchiveCard from "../components/CandidateArchiveCard.vue";
import ArchivedAssessments from "../components/ArchivedAsessments.vue";
import AllArchiveAssessments from "@/components/AllArchiveAssessments.vue";
import LoadingComponent from "../components/LoadingComponent.vue";
import { useStore } from "../store/index.js";

export default {
    name: "ArchieveView",
    components: {
        CandidateArchiveCard,
        ArchivedAssessments,
        AllArchiveAssessments,
        LoadingComponent,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    data() {
        return {
            display: "candidates",
            showAssessments: false,
            selected: [true, false],
            current: 0,
            archivedCandidates: [],
            candidatesInfo: [],
            isLoading: true,
        };
    },
    methods: {
        toggleLoading() {
            this.isLoading = !this.isLoading;
        },
        selectTab(num) {
            this.selected[this.current] = false;
            this.selected[num] = true;
            this.current = num;
        },
        toggleAssessmentsArchive() {
            this.display = "assessment";
        },
        toggleCandidatesArchive() {
            this.display = "candidates";
        },
        toggleAllAssessmentsArchive() {
            this.showAssessments = !this.showAssessments;
        },
        getCandidate(email) {
            return this.Store.archivedCandidatesInfo.find((candidate) => candidate.Email === email);
        },
    },
    async mounted() {
        await this.Store.getArchivedCandidates();
        this.toggleLoading();
    },
};
</script>

<style scoped lang="scss">
.archiveWrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-left: 2rem;

    .btns_wrapper {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;

        .btns {
            width: 25%;
            display: flex;
            justify-content: space-between;
            background-color: white;
            border-radius: 20px;

            button {
                width: 50%;
                border: none;
                cursor: pointer;
                font-weight: 500;
                font-size: 16px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    font-size: 14px;
                }
            }

            .active {
                color: white;
                font-weight: 700;
                background-color: #2196f3;
                border-radius: 20px;
            }

            .notActive {
                color: #82888d;
                background-color: white;
                border-radius: 20px;
            }
        }
    }

    .candidatesWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }
}

.navigation-tabs {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 20px;

    border-bottom: 2px solid #edeff2;
}

.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #7d8fb3;
    margin-right: 24px;
    position: relative;
}

.nav-links .nav-icon {
    font-size: 20px;
}

.nav-links img {
    margin-right: 12px;
}

.searchbar {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 0 16px;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 11px;
    color: #adb8cc;
}

input {
    border: none;
}

input:focus {
    outline: none;
}

.nav-links a::after {
    content: "";
    background: #7d8fb3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: width 0.4s ease-in-out;
    border-radius: 25px;
}

.nav-links > a:hover::after {
    width: 100%;
}

.nav-links a.active::after {
    background: #2196f3;
    width: 100%;
}

.nav-links a.active * {
    color: #2196f3;
}

.noCandidates {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 6%;
    h1 {
        color: #0f172a;
        font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        /* 116.667% */
        letter-spacing: -0.72px;
    }
}
</style>
